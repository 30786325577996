/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    h2: "h2",
    h3: "h3",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "The Lexus LY 680: A Pinnacle of Luxury on the Water"), "\n", React.createElement(_components.p, null, "The Lexus LY 680 represents the zenith of maritime luxury, setting a new standard for high-end yachting experiences. With a starting price of around $5 million, this luxury yacht is designed for those who seek unparalleled elegance and sophistication on the water."), "\n", React.createElement(_components.h2, null, "Unmatched Luxury and Design"), "\n", React.createElement(_components.p, null, "The LY 680 is not just a yacht; it's a statement of luxury, reflecting Lexus's commitment to excellence in design, performance, and comfort. Its price tag is a testament to the premium materials, advanced technology, and meticulous craftsmanship that go into each vessel."), "\n", React.createElement(_components.h3, null, "Price and Customization"), "\n", React.createElement(_components.p, null, "The base price of approximately $5 million can increase with additional customization and optional features, allowing owners to tailor the yacht to their personal preferences and requirements. This flexibility ensures that each LY 680 is as unique as its owner, with bespoke elements that enhance the yachting experience."), "\n", React.createElement(_components.h2, null, "References"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.autonews.com/marketing/lexus-floats-new-boat-luxury-yacht-stickering-5-million"
  }, "Lexus floats a new boat: Luxury yacht stickering for $5 million")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://qz.com/lexus-yacht-luxury-vehicle-1851364812"
  }, "The most powerful Lexus ever is actually a $5 million yacht")), "\n"), "\n", React.createElement(_components.p, null, "The Lexus LY 680 is more than a yacht; it's a masterpiece of naval engineering and design, offering an unmatched level of luxury for those who demand the very best. Whether cruising the open seas or anchored in a secluded bay, the LY 680 promises an exquisite and memorable experience on the water."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
